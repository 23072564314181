import * as React from 'react';
import {Button} from 'react-bootstrap';
import {ContentModal} from '../ui/modals/ContentModal';

interface PromoNotMetModalProps {
    campaignId: string;
    hasLandingPage: boolean;
    onClose: () => void;
    show: boolean;
}

export const PromoNotMetModal = ({campaignId, hasLandingPage, onClose, show}: PromoNotMetModalProps) => (
    <ContentModal
        footer={
            <div className="text-right">
                {hasLandingPage && (
                    <a
                        className="btn-modal-cancel"
                        href={`/deals/${campaignId}`}
                    >
                        Learn More
                    </a>
                )}
                <Button
                    className="btn-modal-action"
                    onClick={onClose}
                    variant="secondary"
                >
                    Continue Shopping
                </Button>
            </div>
        }
        onClose={onClose}
        show={show}
        title="Promotion Details"
    >
        <p>The minimum requirements have not yet been met for this promotion.</p>
    </ContentModal>
);
