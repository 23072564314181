import {FetchService} from '../../fetch/fetch.service';
import {Signal} from '../../../server/search/signals/signal.class';

export class SignalsService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Send signals to the server
     * @param signals
     */
    public sendSignals(signals: Signal[]) {
        return this._fetchService.post(`/api/sendSignals`, {signals}, true);
    }
}
