import * as React from 'react';
import {useEffect} from 'react';

interface OverlayProps {
    isMenu: boolean;
    show: boolean;
}
export const Overlay = ({isMenu, show}: OverlayProps) => {
    const overlayClass = show ? `tw-fixed tw-inset-0 tw-bg-gray-650 tw-bg-opacity-50 tw-z-50 ${isMenu && 'tw-top-[204px]'}` : 'tw-hidden';

    // Prevent scrolling when the overlay is displayed
    useEffect(() => {
        if (show) {
            document.body.classList.add('tw-overflow-hidden');
            document.documentElement.classList.add('tw-overflow-hidden');
        } else {
            document.body.classList.remove('tw-overflow-hidden');
            document.documentElement.classList.remove('tw-overflow-hidden');
        }
    }, [show]);

    /**
     * Template
     */
    return <div className={overlayClass} />;
};
