import * as React from 'react';

import ImpressionTracker from '../analytics/impression-tracker/ImpressionTracker';
import {GaItemListName} from '../../client/ga/ga-ecommerce.functions';
import {OrdersService} from '../../client/orders/orders.service';
import {Price} from '../pricing/Price';
import {TopProductsItem} from './TopProductsItem';
import {TopProduct} from '../items/item.class';
import {User} from '../users/user.class';
import {useService} from '../react/ServiceContext';

interface TopProductsSectionProps {
    alias: `col1` | `col2`;
    closeMegaMenu: () => void;
    setAddToOrderError: React.Dispatch<React.SetStateAction<string>>;
    topProducts: TopProduct[];
    user: User;
}

export const TopProductsSection = ({alias, closeMegaMenu, setAddToOrderError, topProducts, user}: TopProductsSectionProps) => {
    const componentName: GaItemListName = `TopProductsSection-${alias}`;
    const ordersService: OrdersService = useService(`ordersService`);

    /**
     * Template
     */
    return (
        <div className="col-6">
            <ul>
                {topProducts.map((topProduct, index) => (
                    <li key={topProduct.id}>
                        <div className="item-thumb">
                            <ImpressionTracker
                                as="span"
                                data={{
                                    dimension16: ordersService?.currentOrderNumber,
                                    index: index + 1,
                                    item_id: topProduct.id,
                                    item_list_name: componentName,
                                }}
                            >
                                <a href={`/item/${topProduct.id}`}>
                                    <img
                                        alt={topProduct.name}
                                        height="64"
                                        src={`/ProductImageThumbs75${topProduct.imagePath || '/noimage.png'}`}
                                        width="64"
                                    />
                                </a>
                            </ImpressionTracker>
                        </div>
                        <div className="item">
                            <div className="item-body">
                                <a
                                    className="text-capitalize product-description"
                                    href={`/item/${topProduct.id}`}
                                >
                                    {topProduct.name.toLowerCase()}
                                </a>
                                <div className="list__itemPriceBlock">
                                    <div className="pt-2">Pkg Qty: {topProduct.pkgQty}</div>
                                    <div className="pt-2">
                                        <Price
                                            alias={componentName}
                                            item={topProduct}
                                            user={user}
                                        />
                                    </div>
                                </div>
                            </div>
                            <TopProductsItem
                                closeMegaMenu={closeMegaMenu}
                                itemNum={topProduct.id}
                                setAddToOrderError={setAddToOrderError}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
