import {Deal} from '../../shared/deals/deal.class';
import {FetchService} from '../fetch/fetch.service';
import {ImpError} from '../imp-error/imp-error.class';
import {SessionStorageService} from '../session-storage/session-storage.service';

export class DealsService {
    constructor(private _fetchService: FetchService, private _sessionStorageService: SessionStorageService) {}

    /**
     * Return all available deals
     */
    public getDeals(): Promise<Deal[]> {
        return new Promise((resolve, reject) => {
            if (this._sessionStorageService.getItem(`deals`)) {
                resolve(JSON.parse(this._sessionStorageService.getItem(`deals`)));
            } else {
                this._fetchService
                    .get<Deal[]>(`/api/deals/getDeals`)
                    .then((getDealsRes) => {
                        this._sessionStorageService.setItem(`deals`, JSON.stringify(getDealsRes));
                        resolve(getDealsRes);
                    })
                    .catch((getDealsErr: ImpError) => {
                        if (getDealsErr.status === 404) {
                            resolve([]);
                        } else {
                            reject(getDealsErr);
                        }
                    });
            }
        });
    }
}
