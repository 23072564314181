import * as React from 'react';
import {faAngleDown, faAngleUp} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';

import Button from '../../ui/Buttons/Button';
import {AddToOrderModalItemCard} from './AddToOrderModalItemCard';
import {Alerts} from '../../ui/Alerts/Alerts';
import {ContentModal} from '../../ui/modals/ContentModal';
import {formatCurrency} from '../../formatCurrency';
import {ItemSpinner} from '../../ui/item-spinner/ItemSpinner';
import {ItemsService} from '../../../client/items/items.service';
import {MonetateService} from '../../../client/monetate/monetate.service';
import {MultipleItemAddItem} from '../../order-items/order-items.class';
import {OrderableItem, SwitchAndSaveItem} from '../../items/item.class';
import {Order} from '../order.class';
import {SwitchAndSave} from '../../items/SwitchAndSave/SwitchAndSave';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

export interface ItemLine {
    hasPandemicFlag: boolean;
    imagePath: string;
    item: string;
    itemLineAmount: number;
    itemLineQty: number;
    msg: string;
    name: string;
    pkgQty: number;
}

interface AddToOrderModalProps {
    addedItems: MultipleItemAddItem[];
    onClose: () => void;
    order: Order;
    show: boolean;
    user: User;
}

export const AddToOrderModal = ({addedItems, onClose, order, show, user}: AddToOrderModalProps) => {
    const [firstItemLine, setFirstItemLine] = useState<ItemLine>(null);
    const [itemLineErrorsState, setItemLineErrorsState] = useState<string[]>([]);
    const [itemLinesState, setItemLinesState] = useState<ItemLine[]>([]);
    const [numAddedState, setNumAddedState] = useState(0);
    const [recoItems, setRecoItems] = useState<OrderableItem[]>([]);
    const [recoLoaded, setRecoLoaded] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [someHavePandemic, setSomeHavePandemic] = useState(false);
    const [switchAndSaveItem, setSwitchAndSaveItem] = useState<SwitchAndSaveItem>(null);
    const componentName = `AddToOrderModal`;
    const itemsService: ItemsService = useService(`itemsService`);
    const monetateService: MonetateService = useService(`monetateService`);

    // Build itemLinesState
    useEffect(() => {
        const itemLines: ItemLine[] = [];
        const itemLineErrors: string[] = [];
        let numAdded = 0;
        for (const addedItem of addedItems) {
            // If errored, use MultipleItemAddItem
            if (addedItem.error) {
                itemLineErrors.push(addedItem.error);
            } else {
                // Else build ItemLine using OrderLine
                const orderLine = order.getOrderLineByItemId(addedItem.item);
                if (orderLine) {
                    numAdded = numAdded + 1;

                    // Calculate itemLineAmount and itemLineQty
                    let itemLineAmount: number;
                    let itemLineQty: number;
                    if (addedItem.unitsOrdered) {
                        if (addedItem.price.pricePkg) {
                            itemLineAmount = addedItem.unitsOrdered * addedItem.price.pricePkg;
                        }
                        itemLineQty = addedItem.unitsOrdered;
                    } else {
                        if (addedItem.price.priceEA) {
                            itemLineAmount = addedItem.qtyOrdered * addedItem.price.priceEA;
                        }
                        itemLineQty = addedItem.qtyOrdered;
                    }
                    let hasPandemicFlag = false;
                    if (orderLine.alerts) {
                        orderLine.alerts.forEach((alert) => {
                            if (alert.pandemicItem) {
                                hasPandemicFlag = true;
                                setSomeHavePandemic(true);
                            }
                        });
                    }

                    // Add to itemLines
                    itemLines.push({
                        hasPandemicFlag,
                        imagePath: orderLine.imagePath,
                        item: addedItem.item,
                        itemLineAmount,
                        itemLineQty,
                        msg: addedItem.msg,
                        name: orderLine.name,
                        pkgQty: orderLine.pkgQty,
                    });
                }
            }
        }

        // Pull out first item line
        if (itemLines.length > 0) {
            setFirstItemLine(itemLines.shift());
        }

        // Set state
        setItemLineErrorsState(itemLineErrors);
        setItemLinesState(itemLines);
        setNumAddedState(numAdded);

        // We only want this logic to run once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Get switch and save item
    useEffect(() => {
        if (addedItems?.length === 1) {
            itemsService
                .getSwitchAndSaveItem(addedItems[0].item)
                .then((getSwitchAndSaveItemRes) => {
                    if (getSwitchAndSaveItemRes) {
                        setSwitchAndSaveItem(getSwitchAndSaveItemRes);
                    }
                })
                .catch(() => {
                    // Error silently
                });
        }
        // We only want this logic to run once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Get reco items for related items spinner
    useEffect(() => {
        // Build itemId param
        const itemidArray = [];
        for (const item of addedItems) {
            itemidArray.push(item.item);
        }

        // Get reco items and display spinner
        if (itemidArray.length) {
            const itemidParam = itemidArray.join(`;`);
            monetateService
                .getRecoItems({
                    itemid: itemidParam,
                    scheme: `addtocart1_rr`,
                })
                .then((getRecoItemsRes) => {
                    setRecoLoaded(true);
                    if (getRecoItemsRes?.schemes) {
                        getRecoItemsRes.schemes.forEach((scheme) => {
                            if (scheme.scheme === `addtocart1_rr`) {
                                setRecoItems(scheme.items);
                            }
                        });
                    }
                })
                .catch(() => {
                    // Error silently
                });
        }
        // We only want this logic to run once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Take user to view their cart or order
     */
    const viewOrder = () => {
        onClose();
        location.assign(`/orders/${order.ordrNbr}`);
    };

    /**
     * Template
     */
    return (
        <ContentModal
            onClose={onClose}
            show={show}
            title={numAddedState > 1 ? `${numAddedState} Items Added to Order` : `1 Item Added to Order`}
        >
            {someHavePandemic && (
                <div className="tw-mb-4">
                    <Alerts
                        message={`Items marked "limited supply" are subject to change due to high demand`}
                        variant="warning"
                    />
                </div>
            )}
            {itemLineErrorsState.map((itemLineError, index) => (
                <Alerts
                    key={index}
                    message={itemLineError}
                    variant="danger"
                />
            ))}
            <div className="row tw-mb-4">
                {firstItemLine && (
                    <>
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="card tw-mb-4 tw-h-full">
                                <AddToOrderModalItemCard
                                    itemLine={firstItemLine}
                                    order={order}
                                    parentComponentName={componentName}
                                />
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 md:tw-mb-4 lg:tw-mb-0">
                            <div className="tw-bg-gray-100 tw-border-2 tw-border-gray-150 tw-p-4 tw-text-center sticky-top tw-rounded tw-h-full tw-flex tw-flex-col tw-justify-between">
                                <div>
                                    <h5 className="tw-mb-2 tw-font-bold">Order Subtotal</h5>
                                    <h5 className="tw-mb-4">
                                        {order.orderLines} Item{order.orderLines > 1 ? `s` : ``}
                                        {(order.amount || order.amount === 0) && <> | {formatCurrency(order.amount, order.currency)}</>}
                                    </h5>
                                    {order.estimatedWeight && (
                                        <>
                                            <div>
                                                Estimated Total Weight:{' '}
                                                <span className="tw-font-bold">{Math.round(parseInt(order.estimatedWeight))} lb</span>
                                            </div>
                                            <div className="caption tw-mb-4">Excludes supplier-shipped items</div>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <Button
                                        className="tw-w-full tw-mb-2 tw-mt-4"
                                        e2e="addToOrderPopup-ViewOrderBtn"
                                        onClick={viewOrder}
                                        size="sm"
                                        variant="primary"
                                    >
                                        View Order
                                    </Button>
                                    <Button
                                        className="tw-w-full"
                                        onClick={onClose}
                                        size="sm"
                                        variant="outline-secondary"
                                    >
                                        Continue Shopping
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="row">
                {itemLinesState.length > 0 && (
                    <div className="col-lg-7">
                        <div className="card tw-mb-4">
                            <div
                                className="tw-cursor-pointer tw-block tw-text-gray-650 tw-py-2 tw-px-4"
                                onClick={() => setShowMore(!showMore)}
                            >
                                <span className="tw-flex tw-justify-between tw-items-center">
                                    {showMore ? `Hide` : `Show`} Additional Items{' '}
                                    <FontAwesomeIcon icon={showMore ? faAngleUp : faAngleDown} />
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                {showMore && (
                    <div className="col-lg-7">
                        {itemLinesState.map((itemLine, index) => (
                            <div
                                key={index}
                                className="card tw-mb-4"
                            >
                                <AddToOrderModalItemCard
                                    itemLine={itemLine}
                                    order={order}
                                    parentComponentName={componentName}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <SwitchAndSave
                featured={true}
                onReplaceAction={onClose}
                orderLineToReplace={order.getOrderLineByItemId(addedItems[0].item)}
                percentSaved={switchAndSaveItem?.pctDiff}
                replacementItem={switchAndSaveItem}
                user={user}
                useReplacementButtons={true}
                valueSaved={switchAndSaveItem?.amtDiff}
            />
            <ItemSpinner
                gaList="scheme_addtocart1_rr"
                label="Related products you may like"
                loaded={recoLoaded}
                maxSlides={4}
                orderableItems={recoItems}
                useAddToOrderModal={true}
                user={user}
            />
        </ContentModal>
    );
};
