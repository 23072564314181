import styled from '@emotion/styled';
import '../../../client/ui/base/_variables.scss';

export const MyAccountContainer = styled.div`
    .account-menu {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
`;

export const MyAccountFlyoutStyle = styled.div`
    .flyoutContainer.show {
        background-color: #333333;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
        filter: alpha(opacity=50);
        opacity: 0.5;
        height: 100%;
        bottom: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1026;
        left: 0;
        right: 0;
    }

    .flyoutMenu {
        background: #ffffff;
        width: 80%;
        max-width: 540px;
        max-height: 100vh !important;
        height: 100vh !important;
        position: fixed;
        top: 0;
        right: 0;
        transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
        z-index: 1027;
    }

    .flyoutBody {
        font-size: 1rem;
        overflow: auto;
        height: calc(100vh - 62px);
    }

    .flyoutMenu.hide {
        transform: translate3d(580px, 0, 0);
    }

    .flyoutMenu.show {
        transform: translate3d(0vw, 0, 0);
        overflow: hidden;
    }

    .account-links {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            margin-top: 20px;
            font-size: 20px;
        }

        ul {
            list-style-type: none;
            margin: 10px 0 0;
            padding: 0;

            li {
                font-size: 16px;
                font-weight: 300;
                line-height: 40px;
                margin-top: 0;
            }

            li a {
                display: block;
            }
        }

        a {
            color: #333333 !important;
        }
    }
`;
