import {CookiesService} from '../cookies/cookies.service';
import {SessionStorageKeyName} from './session-storage.class';

export class SessionStorageService {
    public storageAvailable: boolean;

    constructor(private _cookiesService: CookiesService) {
        this.storageAvailable = SessionStorageService._storageAvailable();
        this._checkClearSessionStorageCookie();
    }

    /**
     * Clears item from sessionStorage
     */
    public clear() {
        if (this.storageAvailable) {
            sessionStorage.clear();
        }
    }

    /**
     * Gets aliased item from sessionStorage
     * @param key - Key item is stored under
     * @param alias - Alias to add to key (for multiple keyed values)
     */
    public getAliasedItem(key: SessionStorageKeyName, alias: string): string {
        if (this.storageAvailable) {
            return sessionStorage.getItem(`${key}_${alias}`);
        }
        return null;
    }

    /**
     * Gets item from sessionStorage
     */
    public getItem(key: SessionStorageKeyName): string {
        if (this.storageAvailable) {
            return sessionStorage.getItem(key);
        }
        return null;
    }

    /**
     * Removes item from sessionStorage
     * @param key - Key item is stored under
     */
    public removeItem(key: SessionStorageKeyName) {
        if (this.storageAvailable) {
            sessionStorage.removeItem(key);
        }
    }

    /**
     * Sets aliased item in sessionStorage
     * @param key - Key item is stored under
     * @param alias - Alias to add to key (for multiple keyed values)
     * @param value - Value to set for key
     */
    public setAliasedItem(key: SessionStorageKeyName, alias: string, value: string) {
        if (this.storageAvailable) {
            sessionStorage.setItem(`${key}_${alias}`, value);
        }
    }

    /**
     * Sets item in sessionStorage
     * @param key - Key item is stored under
     * @param value - Value to set for key
     */
    public setItem(key: SessionStorageKeyName, value: string) {
        if (this.storageAvailable) {
            sessionStorage.setItem(key, value);
        }
    }

    /**
     * Checks and clears session storage
     * @private
     */
    private _checkClearSessionStorageCookie() {
        if (this._cookiesService.getCookie(`clearSessionStorage`) === `true`) {
            this.clear();
            this._cookiesService.eraseCookie(`clearSessionStorage`);
        }
    }

    /**
     * Returns true if client supports use of `sessionStorage`
     * @private
     */
    private static _storageAvailable(): boolean {
        try {
            const storage = window.sessionStorage;
            const x = `__storage_test__`;
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return false;
        }
    }
}
