import styled from '@emotion/styled';
import '../../../client/ui/base/_variables.scss';

export const HeaderMobileMenuStyle = styled.div`
    .header-mobile-menu {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    ul.mobile-main-menu {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            line-height: 40px;
            margin-top: 0;

            .icon {
                width: 40px;
                height: 18px;
                display: inline-block;
            }

            a {
                color: #333333 !important;
                display: block;
            }
        }

        .fa {
            font-size: 18px;
        }
    }

    ul.mobile-sub-menu {
        list-style-type: none;
        margin: 10px 0 0;
        padding: 0;

        li {
            font-size: 16px;
            line-height: 40px;
            margin-top: 0;

            a {
                color: #333333 !important;
            }
        }
    }

    .top-menu {
        max-width: 350px;
        width: 100%;
        height: 100%;
        margin-left: 0;
        transition: margin-left 500ms;

        &.hide {
            margin-left: -350px;
            height: 0;
        }
    }

    .sub-menu {
        top: 0;
        margin-left: 400px;
        width: 100%;
        height: 0;
        z-index: 2000;
        transition: margin-left 500ms;
        overflow-y: auto;
        overflow-x: hidden;

        &.active {
            margin-left: 0;
            height: 100%;
        }

        ul.products {
            list-style-type: none;
            column-count: 1;

            li {
                line-height: 40px;
            }

            li a {
                color: #333333;
            }
        }
    }

    .account-links {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            margin-top: 20px;
            font-size: 20px;
        }

        ul {
            list-style-type: none;
            margin: 10px 0 0;
            padding: 0;

            li {
                font-size: 16px;
                font-weight: 300;
                line-height: 40px;
                margin-top: 0;
            }

            li a {
                display: block;
            }
        }

        a {
            color: #333333 !important;
        }
    }
`;
