import {useEffect, useRef} from 'react';

/**
 * React hook that listens to the beforeunload window event
 * Ref: https://github.com/jacobbuck/react-beforeunload/blob/main/src/useBeforeunload.js
 * @param handler
 */
export function useBeforeunload(handler) {
    const eventListenerRef = useRef<any>();

    // Exception handling
    useEffect(() => {
        eventListenerRef.current = (event) => {
            const returnValue = handler?.(event);

            // Handle legacy `event.returnValue` property
            // Ref: https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
            if (typeof returnValue === `string`) {
                return (event.returnValue = returnValue);
            }

            // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`
            // Ref: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
            if (event.defaultPrevented) {
                return (event.returnValue = ``);
            }
        };
    }, [handler]);

    // beforeunload event handler
    useEffect(() => {
        const eventListener = (event) => eventListenerRef.current(event);
        window.addEventListener(`beforeunload`, eventListener);
        return () => {
            window.removeEventListener(`beforeunload`, eventListener);
        };
    }, []);
}
