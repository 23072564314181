import {FetchService} from '../fetch/fetch.service';

export class CmsService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Full text search limits by Tag
     * @param tag
     * @param search
     */
    public getByTagSearch<T>(tag: string, search: string): Promise<T> {
        return this._fetchService.get<T>(`/api/cms-getbytagsearch/${tag}/${search}`);
    }

    /**
     * Get the prismic record by ID
     * @param id
     */
    public getByID<T>(id: string): Promise<T> {
        return this._fetchService.get<T>(`/api/cms-getbyid/${id}`);
    }

    /**
     * Get the first singleton from prismic of the given type
     * @param type
     */
    public getByType<T>(type: string): Promise<T> {
        return this._fetchService.get<T>(`/api/cms-getbytype/${type}`);
    }
}
