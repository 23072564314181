import {Observer} from 'rxjs';

import {FetchService} from '../fetch/fetch.service';
import {gaAddToCart, GaCartItem, gaRemoveFromCart} from '../ga/ga-ecommerce.functions';
import {ImpError} from '../imp-error/imp-error.class';
import {ImpUrl} from '../imp-url/imp-url.class';
import {ItemToAdd, MultipleItemAddItem, MultipleItemAddRes, MultipleItemAddTypes} from '../../shared/order-items/order-items.class';
import {Item} from '../../shared/items/item.class';
import {LocalStorageService} from '../local-storage/local-storage.service';
import {MonetateItem} from '../../shared/monetate/monetate.types';
import {MonetateService} from '../monetate/monetate.service';
import {Order, OrderLine} from '../../shared/orders/order.class';
import {OrdersService} from '../orders/orders.service';
import {UserStateService} from '../users/user-state.service';

const ADD_TO_ORDER_ITEM_CHUNK_SIZE = 50;

export class OrderItemsService {
    constructor(
        private _fetchService: FetchService,
        private _localStorageService: LocalStorageService,
        private _monetateService: MonetateService,
        private _ordersService: OrdersService,
        private _userStateService: UserStateService,
    ) {}

    /**
     * Deletes item from backorder
     * @param orderNumber - Order number to delete item from
     * @param itemNum - Item to delete
     */
    public deleteBackorderItem(orderNumber: string, itemNum: string) {
        return this._fetchService.delete(`/api/order-items/deleteBackorderItem/${orderNumber}?itemNum=${itemNum}`);
    }

    /**
     * Deletes a single item from the current order
     * @param orderLine - Line to delete from the order
     */
    public deleteFromOrder(orderLine: OrderLine): Promise<string> {
        return new Promise((resolve, reject) => {
            this._fetchService
                .post<string>(
                    `/api/orders/deleteItem`,
                    {
                        item: orderLine.item,
                    },
                    true,
                )
                .then((deleteFromOrderRes) => {
                    // Record analytics
                    gaRemoveFromCart([
                        {
                            dimension16: this._ordersService.currentOrderNumber,
                            item_id: orderLine.item,
                            item_list_name: ``,
                            price: orderLine.pricePK,
                            quantity: orderLine.unitsOrdered,
                        },
                    ]);

                    // Refresh currentOrder
                    this._ordersService
                        .loadOrder(this._ordersService.currentOrderNumber)
                        .then(() => {
                            resolve(deleteFromOrderRes);
                        })
                        .catch((loadOrderErr: ImpError) => {
                            reject(loadOrderErr);
                        });
                })
                .catch((deleteFromOrderErr: ImpError) => {
                    reject(deleteFromOrderErr);
                });
        });
    }

    /**
     * Deletes multiple items from the current order
     * @param componentName - Component performing the action
     * @param itemsToRemove - Items to remove
     */
    public deleteOrderLines(componentName: string, itemsToRemove: ItemToAdd[]): Promise<MultipleItemAddRes> {
        // Record deleteOrderLines action
        this._userStateService.recordAddToOrderAction(componentName, itemsToRemove, `delete`);

        // Call mitemadd.p
        return this.multipleItemAdd();
    }

    /**
     * Loads specified order and adds itemsToAdd
     * @param orderNumber - Order to load and add items to
     * @param observer - Used to communicate response
     * @private
     */
    public loadOrderAddItems(orderNumber: string, observer: Observer<MultipleItemAddRes>) {
        this._ordersService
            .loadOrder(orderNumber)
            .then(() => {
                this.multipleItemAdd()
                    .then((multipleItemAddRes) => {
                        observer.next(multipleItemAddRes);
                        observer.complete();
                    })
                    .catch((multipleItemAddErr: ImpError) => {
                        observer.error(multipleItemAddErr);
                        observer.complete();
                    });
            })
            .catch((loadOrderErr: ImpError) => {
                observer.error(loadOrderErr);
                observer.complete();
            });
    }

    /**
     * Adds an array of items to the specified order
     */
    public multipleItemAdd(): Promise<MultipleItemAddRes> {
        return new Promise((resolve, reject) => {
            // Determine if currentOrder is editable
            this._ordersService
                .getCurrentOrder()
                .then((getCurrentOrderRes) => {
                    if (Order.isEditable(getCurrentOrderRes)) {
                        // Split itemsToAdd into smaller chunks
                        const addToOrderItemsChunkArray = OrderItemsService._splitAddToOrderItemsIntoChunks(
                            this._userStateService.addToOrderItems,
                        );

                        // Perform order add
                        this._sendAllAddToOrderItemsChunks(
                            this._ordersService.currentOrderNumber,
                            addToOrderItemsChunkArray,
                            this._userStateService.addToOrderMethod,
                            this._userStateService.componentName,
                        )
                            .then((multipleItemAddResArray) => {
                                // Add back addToOrderErroredItems for messaging
                                for (const addToOrderErroredItem of this._userStateService.addToOrderErroredItems) {
                                    multipleItemAddResArray[0].items.push({
                                        error: addToOrderErroredItem.error,
                                        imagePath: undefined,
                                        item: addToOrderErroredItem.item,
                                        list: addToOrderErroredItem.list,
                                        pkgQty: undefined,
                                        price: undefined,
                                        qtyOrdered: addToOrderErroredItem.qtyOrd ? addToOrderErroredItem.qtyOrd : undefined,
                                        result: `Error`,
                                        unitsDiff: 0,
                                        unitsOrdered: addToOrderErroredItem.unitsOrdered ? addToOrderErroredItem.unitsOrdered : undefined,
                                    });
                                }
                                this._userStateService.clearPendingAction();

                                // If only one added item, and it has an error, return as an error
                                if (
                                    multipleItemAddResArray[0] &&
                                    multipleItemAddResArray[0].items &&
                                    multipleItemAddResArray[0].items.length === 1 &&
                                    multipleItemAddResArray[0].items[0].result === `Error`
                                ) {
                                    reject(new Error(multipleItemAddResArray[0].items[0].error));

                                    // Refresh currentOrder
                                } else {
                                    this._ordersService
                                        .loadOrder(this._ordersService.currentOrderNumber)
                                        .then(() => {
                                            // Build multipleItemAddRes using allResults
                                            const multipleItemAddRes: MultipleItemAddRes = {
                                                result: `OK`,
                                                items: [],
                                            };
                                            for (const response of multipleItemAddResArray) {
                                                multipleItemAddRes.items.push(...response.items);
                                            }
                                            resolve(multipleItemAddRes);
                                        })
                                        .catch((loadOrderErr: ImpError) => {
                                            reject(loadOrderErr);
                                        });
                                }
                            })
                            .catch((multipleItemAddErr: ImpError) => {
                                this._userStateService.clearPendingAction();
                                reject(multipleItemAddErr);
                            });
                    } else {
                        reject(new Error(`Order is not editable`));
                    }
                })
                .catch((getCurrentOrderErr: ImpError) => {
                    reject(getCurrentOrderErr);
                });
        });
    }

    /**
     * Replaces designated orderLine with itemNumToAdd
     * @param orderLineToRemove - Order line to replace
     * @param itemToAdd - Item to add to order
     * @param componentName - Component performing replacement
     */
    public replaceOrderLine(orderLineToRemove: OrderLine, itemToAdd: ItemToAdd, componentName: string): Promise<MultipleItemAddRes> {
        return new Promise((resolve, reject) => {
            const orderNbr = this._ordersService.currentOrderNumber;
            this._fetchService
                .post<MultipleItemAddRes>(`/api/order-items/replaceOrderLine`, {
                    componentName,
                    itemNumberToRemove: orderLineToRemove.item,
                    itemToAdd,
                    orderNbr,
                })
                .then((replaceOrderLine) => {
                    // Record analytics on removed item
                    gaRemoveFromCart([
                        {
                            dimension16: orderNbr,
                            item_id: orderLineToRemove.item,
                            item_list_name: ``,
                            price: orderLineToRemove.pricePK,
                            quantity: orderLineToRemove.unitsOrdered,
                        },
                    ]);

                    // Record analytics on added item
                    gaAddToCart(
                        [
                            {
                                dimension16: orderNbr,
                                item_id: replaceOrderLine.items[0].item,
                                item_list_name: itemToAdd.list,
                                price: replaceOrderLine.items[0].price?.pricePkg || 0,
                                quantity: replaceOrderLine.items[0].unitsDiff,
                            },
                        ],
                        this._localStorageService.getItem(`searchTerm`)?.toLowerCase() || ``,
                    );
                    this._monetateService.addCart(this._getAnalyticsPageType(componentName), [
                        {
                            currency: `USD`,
                            productId: replaceOrderLine.items[0].item,
                            quantity: replaceOrderLine.items[0].unitsDiff,
                            unitPrice: replaceOrderLine.items[0].price?.pricePkg || 0,
                        },
                    ]);

                    // Refresh currentOrder
                    this._ordersService
                        .loadOrder(orderNbr)
                        .then(() => {
                            resolve(replaceOrderLine);
                        })
                        .catch((loadOrderErr: ImpError) => {
                            reject(loadOrderErr);
                        });
                })
                .catch((replaceOrderLineErr: ImpError) => {
                    reject(replaceOrderLineErr);
                });
        });
    }

    /**
     * Validates items to add
     * @param itemsToAdd
     * @private
     */
    public static validateItemsToAdd(itemsToAdd: ItemToAdd[]): ItemToAdd[] {
        // Convert itemId's to uglyId's
        for (const item of itemsToAdd) {
            item.item = Item.uglyItem(item.item);
        }

        // Validate itemsToAdd
        const validatedItemsToAdd = [];
        for (const itemToAdd of itemsToAdd) {
            // Require valid unitsOrdered or qtyOrd
            if (
                (!itemToAdd.unitsOrdered && !itemToAdd.qtyOrd) ||
                (itemToAdd.unitsOrdered && itemToAdd.unitsOrdered < 1) ||
                (itemToAdd.unitsOrdered && typeof itemToAdd.unitsOrdered !== `number`) ||
                (itemToAdd.qtyOrd && itemToAdd.qtyOrd < 1) ||
                (itemToAdd.qtyOrd && typeof itemToAdd.qtyOrd !== `number`)
            ) {
                itemToAdd.error = `An invalid quantity was specified`;
            }

            // Add to validatedItemsToAddArray
            validatedItemsToAdd.push(itemToAdd);
        }

        // Return validatedItemsToAdd
        return validatedItemsToAdd;
    }

    /**
     * Returns pageType in a format expected for analytics
     * @param componentName - Component event occurred
     * @private
     */
    private _getAnalyticsPageType(componentName: string): string {
        return ImpUrl.getUrlSegment(1) ? `${ImpUrl.getUrlSegment(1)}:${componentName}` : `home:${componentName}`;
    }

    /**
     * Returns quantity of an item for analytics, considering potential absence of unitsDiff
     * @param multipleItemAddItem
     * @private
     */
    private _getAnalyticsQuantity(multipleItemAddItem: MultipleItemAddItem): number {
        return multipleItemAddItem.unitsDiff ? multipleItemAddItem.unitsDiff : multipleItemAddItem.unitsOrdered;
    }

    /**
     * TBD
     * @param orderNbr - Order number to add items to
     * @param addToOrderItemsChunkArray
     * @param method - Increment (update), replace (add) or remove (delete) order lines
     * @param componentName - Component performing the addToOrder
     * @private
     */
    private async _sendAllAddToOrderItemsChunks(
        orderNbr: string,
        addToOrderItemsChunkArray: ItemToAdd[][],
        method: MultipleItemAddTypes,
        componentName: string,
    ): Promise<MultipleItemAddRes[]> {
        const multipleItemAddResArray: MultipleItemAddRes[] = [];
        for (const itemToAdd of addToOrderItemsChunkArray) {
            multipleItemAddResArray.push(await this._sendAddToOrderItemsChunk(orderNbr, itemToAdd, method, componentName));
        }
        return multipleItemAddResArray;
    }

    /**
     * Performs API call to /multipleItemAdd
     * @param orderNbr - Order number to add items to
     * @param itemsToAdd - Items to add to the order
     * @param method - Increment (update), replace (add) or remove (delete) order lines
     * @param componentName - Component performing the addToOrder
     * @private
     */
    private _sendAddToOrderItemsChunk(
        orderNbr: string,
        itemsToAdd: ItemToAdd[],
        method: MultipleItemAddTypes,
        componentName: string,
    ): Promise<MultipleItemAddRes> {
        return new Promise((resolve, reject) => {
            this._fetchService
                .post<MultipleItemAddRes>(`/api/orders/multipleItemAdd`, {
                    componentName,
                    itemsToAdd,
                    method,
                    orderNbr,
                })
                .then((multipleItemAddRes) => {
                    // Build analytics items arrays
                    if (multipleItemAddRes.items && multipleItemAddRes.items.length) {
                        const gaCartItemsAdded: GaCartItem[] = [];
                        const gaCartItemsRemoved: GaCartItem[] = [];
                        const monetateItemsAdded: MonetateItem[] = [];
                        for (const multipleItemAddItem of multipleItemAddRes.items) {
                            if (multipleItemAddItem.result === `OK`) {
                                // Restore list from original itemToAdd for tracking
                                for (const itemToAdd of itemsToAdd) {
                                    if (itemToAdd.item === multipleItemAddItem.item) {
                                        multipleItemAddItem.list = itemToAdd.list;
                                        break;
                                    }
                                }
                                if (this._getAnalyticsQuantity(multipleItemAddItem) > 0) {
                                    // Record additions
                                    monetateItemsAdded.push({
                                        currency: `USD`,
                                        productId: multipleItemAddItem.item,
                                        quantity: this._getAnalyticsQuantity(multipleItemAddItem),
                                        unitPrice: multipleItemAddItem.price?.pricePkg || 0,
                                    });
                                    gaCartItemsAdded.push({
                                        dimension16: this._ordersService.currentOrderNumber,
                                        item_id: multipleItemAddItem.item,
                                        item_list_name: multipleItemAddItem.list || ``,
                                        price: multipleItemAddItem.price?.pricePkg || 0,
                                        quantity: this._getAnalyticsQuantity(multipleItemAddItem),
                                    });
                                } else {
                                    // Record removals
                                    gaCartItemsRemoved.push({
                                        dimension16: this._ordersService.currentOrderNumber,
                                        item_id: multipleItemAddItem.item,
                                        item_list_name: multipleItemAddItem.list || ``,
                                        price: multipleItemAddItem.price?.pricePkg || 0,
                                        quantity: Math.abs(this._getAnalyticsQuantity(multipleItemAddItem)),
                                    });
                                }
                            }
                        }

                        // Send analytics items arrays
                        if (gaCartItemsRemoved.length) {
                            gaRemoveFromCart(gaCartItemsRemoved);
                        }
                        if (gaCartItemsAdded.length) {
                            gaAddToCart(gaCartItemsAdded, this._localStorageService.getItem(`searchTerm`)?.toLowerCase() || ``);
                        }
                        if (monetateItemsAdded.length) {
                            this._monetateService.addCart(this._getAnalyticsPageType(componentName), monetateItemsAdded);
                        }
                    }
                    resolve(multipleItemAddRes);
                })
                .catch((multipleItemAddErr: ImpError) => {
                    reject(multipleItemAddErr);
                });
        });
    }

    /**
     * TBD
     * @param addToOrderItems
     * @private
     */
    private static _splitAddToOrderItemsIntoChunks(addToOrderItems: ItemToAdd[]): ItemToAdd[][] {
        const addToOrderItemsChunkArray = [];
        for (let i = 0; i < addToOrderItems.length; i += ADD_TO_ORDER_ITEM_CHUNK_SIZE) {
            addToOrderItemsChunkArray.push(addToOrderItems.slice(i, i + ADD_TO_ORDER_ITEM_CHUNK_SIZE));
        }
        return addToOrderItemsChunkArray;
    }
}
