import {MobileType} from '../orders/order.class';

export type PromoCodeAction = 'add' | 'change' | 'remove' | 'verify';

export interface CorpAllowance {
    allowanceDesc: string;
    allowancePcode: string;
    expDate: string;
    lastUpdate: string;
    remainingBal: number;
    result: string;
    thankYouMsg: string;
    webImage: string;
}

export interface PromoItem {
    emailAddress: string;
    h1HTML: string;
    image: string;
    imagePath: string;
    item: string;
    itemDesc: string;
    itemType: string;
    limitReached: boolean;
    qualify: boolean;
    selected: boolean;
}

export interface PromoTierOption {
    items: PromoItem[];
    limitReached: boolean;
    qualify: boolean;
    ruleDesc: string;
    ruleid: number;
    selected: boolean;
    type: string;
}

export interface UpdatePromoCodeParams {
    account: string;
    impersonate?: string;
    loginName: string;
    mobileType?: MobileType;
    punchoutAppName?: string;
    selectedPromoEmail?: string;
    selectedPromoItem?: string;
    selectedPromoTier?: string;
}

export interface UpdatePromoCodeRes {
    msg: string;
    result: string;
    options?: {
        applied: string;
        options: PromoTierOption[];
    };
}

export class Promo {
    /**
     * Returns PromoTierOption[]
     * @param updatePromoCodeRes
     */
    public static getPromoTierOptions(updatePromoCodeRes: UpdatePromoCodeRes): PromoTierOption[] {
        return (updatePromoCodeRes && updatePromoCodeRes.options && updatePromoCodeRes.options.options) || null;
    }
}
