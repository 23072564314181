import {EventDay} from '../../shared/orders/order.class';
import {FetchService} from '../fetch/fetch.service';

export class TrackingService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Loads shipping details from server
     * @param orderNumber
     * @param zipCode
     * @param trackingNumber
     */
    public loadShippingDetails(orderNumber: string, zipCode: string, trackingNumber: string) {
        return this._fetchService.post<EventDay[]>(`/api/tracking/loadShippingDetails/${encodeURIComponent(orderNumber)}`, {
            zipCode,
            trackingNumber,
        });
    }
}
