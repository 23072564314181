require('./snackbar.component.scss');

export class SnackbarComponent {
    public component: JQuery;

    constructor(private _message: string) {
        this.component = $(`[data-component='snackbar-component']`);
        this._onInit();
    }

    private _onInit() {
        this._showNotification();
    }

    /**
     * Hides notification
     * @private
     */
    private _hideNotification() {
        this.component.removeClass(`show`);
    }

    /**
     * Displays provided message as a toast
     * Hides after 1500ms
     * @private
     */
    private _showNotification() {
        this.component.html(this._message);
        this.component.addClass(`show`);

        setTimeout(() => {
            this._hideNotification();
        }, 1500);
    }
}
