import {FetchService} from '../fetch/fetch.service';
import {StandardJSONResponse} from '../../shared/tools/AS.types';
import {
    AutoRoEnrollment,
    AutoRoShipment,
    UpdateAutoRoItemParams,
    UpdateAutoRoShipmentParams,
} from '../../shared/auto-reorders/AutoReorderClass';

export class AutoReordersService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Deletes auto-reorder item
     * @param itemNum - Item to delete
     */
    public deleteAutoRoItem(itemNum: string) {
        return this._fetchService.delete<StandardJSONResponse>(`/api/auto-reorders/deleteAutoRoItem/${itemNum}`);
    }

    /**
     * Retrieves existing auto-reorder enrollment or builds default
     * @param itemNum - Item to get auto-reorder info for
     * @param requestedQty - Requested auto-reorder quantity
     */
    public getAutoRoEnrollment(itemNum: string, requestedQty?: number) {
        // Build url
        let url = `/api/auto-reorders/getAutoRoEnrollment/${itemNum}`;
        if (requestedQty) {
            url += `?requestedQty=${requestedQty}`;
        }
        // Get auto-reorder enrollment
        return this._fetchService.get<AutoRoEnrollment>(url);
    }

    /**
     * Returns all available auto-reorder shipments
     * @param nextorddt - A specific date of shipments to return
     */
    public getAutoRoShipments(nextorddt?: string) {
        // Build url
        let url = `/api/auto-reorders/getAutoRoShipments`;
        if (nextorddt) {
            url += `?nextorddt=${nextorddt}`;
        }

        // Get auto RO shipments
        return this._fetchService.get<AutoRoShipment[]>(url);
    }

    /**
     * Replaces old item with new enrollment
     * @param oldItemNum - Item to replace
     * @param updateAutoRoItemParams - New enrollment params
     */
    public replaceAutoRoItem(oldItemNum: string, updateAutoRoItemParams: UpdateAutoRoItemParams) {
        return this._fetchService.post<StandardJSONResponse>(`/api/auto-reorders/replaceAutoRoItem/${oldItemNum}`, updateAutoRoItemParams);
    }

    /**
     * Triggers auto-reorder shipment to send now
     * @param shipmentDate - Shipment to send today
     */
    public sendAutoRoShipment(shipmentDate: string) {
        return this._fetchService.post<StandardJSONResponse>(`/api/auto-reorders/sendAutoRoShipment`, {shipmentDate});
    }

    /**
     * Updates auto-reorder for provided item
     * @param updateAutoRoItemParams
     */
    public updateAutoRoItem(updateAutoRoItemParams: UpdateAutoRoItemParams) {
        return this._fetchService.post<StandardJSONResponse>(`/api/auto-reorders/updateAutoRoItem`, updateAutoRoItemParams);
    }

    /**
     * Updates auto-reorder shipment with provided params
     * @param updateAutoRoShipmentParams
     */
    public updateAutoRoShipment(updateAutoRoShipmentParams: UpdateAutoRoShipmentParams) {
        return this._fetchService.post<StandardJSONResponse>(`/api/auto-reorders/updateAutoRoShipment`, updateAutoRoShipmentParams);
    }
}
