import * as React from 'react';
import {Button} from 'react-bootstrap';
import {faBoltLightning} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormProvider, useForm} from 'react-hook-form';
import {useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';

import {gaLegacyCustomEvent} from '../../../client/ga/ga-legacy.functions';
import {headerQuickAddValidation} from './HeaderQuickAdd.validation';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {ImpUrl} from '../../../client/imp-url/imp-url.class';
import {Input} from '../../ui/forms/Input/Input';
import {ItemToAdd} from '../../order-items/order-items.class';
import {Item} from '../../items/item.class';
import {LinkButton} from '../../ui/LinkButton/LinkButton';
import {ListsWorkflow} from '../../../client/lists/lists.workflow';
import {OrderItemsWorkflow} from '../../../client/order-items/order-items.workflow';
import {SelectedMenu} from '../mega-menu/MegaMenu';
import {UpdateItemsOnListActionTypes, UpdateListItem} from '../../lists/list.class';
import {UrlClass} from '../../tools/UrlClass';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

export interface HeaderQuickAddProps {
    closeMegaMenu: () => void;
    componentName: string;
    openSelectedMenu: (selectedMenu: SelectedMenu) => void;
    setErrorMessage: (value: React.SetStateAction<string>) => void;
    user: User;
}

export const HeaderQuickAdd = ({closeMegaMenu, componentName, openSelectedMenu, setErrorMessage, user}: HeaderQuickAddProps) => {
    const [enableFormOperations, setEnableFormOperations] = useState(true);
    const [item1SuccessMessage, setItem1SuccessMessage] = useState(``);
    const [item2SuccessMessage, setItem2SuccessMessage] = useState(``);
    const [item3SuccessMessage, setItem3SuccessMessage] = useState(``);
    const [item4SuccessMessage, setItem4SuccessMessage] = useState(``);
    const listsWorkflow: ListsWorkflow = useService(`listsWorkflow`);
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const useFormReturn = useForm({mode: `onBlur`, resolver: yupResolver(headerQuickAddValidation)});

    /**
     * Adds entered items to list
     */
    const addItemsToList = () => {
        // Build addToListItems
        const addToListItems: UpdateListItem[] = [];
        const itemsToAdd = buildItemAddArray();
        itemsToAdd.forEach((itemToAdd) => {
            addToListItems.push({
                item: itemToAdd.item,
                quantity: itemToAdd.unitsOrdered,
            });
        });

        // Add items to list
        closeMegaMenu();
        addToList(`add`, addToListItems);
    };

    /**
     * Performs add to list for component
     * @param action - Action to perform
     * @param addToListItems - Items to add to the list
     */
    const addToList = (action: UpdateItemsOnListActionTypes, addToListItems: UpdateListItem[]) => {
        listsWorkflow
            .addToListObservable(action, addToListItems, componentName, `quick-add megamenu`, null, null, closeMegaMenu)
            .subscribe({
                next: (addToListObservableRes) => {
                    // Determine numErrored and numAdded
                    let numAdded = 0;
                    let numErrored = 0;
                    for (const item of addToListObservableRes.items) {
                        if (item.result === `Error`) {
                            numErrored++;
                        } else if (item.result === `OK`) {
                            numAdded++;
                        }
                    }

                    // If no errors, reset form
                    if (numAdded > 0 && !numErrored) {
                        useFormReturn.reset();
                    } else {
                        // Else display individual error messaging
                        openSelectedMenu(`quickAddDropdown`);
                        const itemValues = useFormReturn.getValues([`item1`, `item2`, `item3`, `item4`]);
                        for (const item of addToListObservableRes.items) {
                            // Get itemIndex
                            let itemIndex = 0;
                            let itemFound = false;
                            itemValues.forEach((itemValue, index) => {
                                if (!itemFound && Item.uglyItem(itemValue) === item.item) {
                                    itemIndex = index;
                                    itemFound = true;
                                }
                            });

                            // Display appropriate error or reset input
                            if (itemFound && item.result === `Error`) {
                                useFormReturn.setError(`item${itemIndex + 1}`, {message: `Invalid Item`});
                            } else if (itemFound && item.result === `OK`) {
                                // Reset input
                                useFormReturn.setValue(`item${itemIndex + 1}`, ``);
                                useFormReturn.setValue(`qty${itemIndex + 1}`, `1`);
                            }
                        }
                    }
                },
                error: (addToListObservableErr: ImpError) => {
                    showErrorModal(addToListObservableErr.message);
                },
            });
    };

    /**
     * Performs order add for component
     * @param itemsToAdd - Items to add to the order
     */
    const addToOrder = (itemsToAdd: ItemToAdd[]) => {
        gaLegacyCustomEvent({eventAction: `Add to Order Clicks`, eventCategory: `Ecommerce`, eventLabel: componentName});
        if (ImpUrl.onOrderDetailPage) {
            orderItemsWorkflow.addToOrderToast(itemsToAdd, componentName, true, null, closeMegaMenu).subscribe({
                next: (addToOrderToastRes) => {
                    if (addToOrderToastRes?.items) {
                        // Determine numErrored and numAdded
                        let numAdded = 0;
                        let numErrored = 0;
                        for (const item of addToOrderToastRes.items) {
                            if (item.result === `Error`) {
                                numErrored++;
                            } else if (item.result === `OK`) {
                                numAdded++;
                            }
                        }

                        // If no errors, reset form
                        if (numAdded > 0 && !numErrored) {
                            useFormReturn.reset();
                        } else {
                            // Else display individual success and error messaging
                            const itemValues = useFormReturn.getValues([`item1`, `item2`, `item3`, `item4`]);
                            for (const item of addToOrderToastRes.items) {
                                // Get itemIndex
                                let itemIndex = 0;
                                let itemFound = false;
                                itemValues.forEach((itemValue, index) => {
                                    if (!itemFound && Item.uglyItem(itemValue) === item.item) {
                                        itemIndex = index;
                                        itemFound = true;
                                    }
                                });

                                // Display appropriate success or error messaging
                                if (itemFound && item.result === `Error`) {
                                    useFormReturn.setError(`item${itemIndex + 1}`, {message: `Invalid Entry`});
                                } else if (itemFound && item.result === `OK`) {
                                    switch (itemIndex) {
                                        case 0:
                                            setItem1SuccessMessage(`Added to Order`);
                                            break;
                                        case 1:
                                            setItem2SuccessMessage(`Added to Order`);
                                            break;
                                        case 2:
                                            setItem3SuccessMessage(`Added to Order`);
                                            break;
                                        case 3:
                                            setItem4SuccessMessage(`Added to Order`);
                                            break;
                                    }
                                }
                            }
                        }
                    }

                    // Allow additional updates
                    setEnableFormOperations(true);
                },
                error: (addToOrderToastErr: ImpError) => {
                    showErrorModal(addToOrderToastErr.message);
                },
            });
        } else {
            orderItemsWorkflow.addToOrderModalObservable(itemsToAdd, componentName, true, closeMegaMenu).subscribe({
                next: () => {
                    setEnableFormOperations(true);
                    useFormReturn.reset();
                },
                error: () => {
                    setEnableFormOperations(true);
                },
            });
        }
    };

    /**
     * Builds ItemToAdd[] from form
     */
    const buildItemAddArray = (): ItemToAdd[] => {
        const itemToAddArray: ItemToAdd[] = [];
        const itemNumbers = useFormReturn.getValues([`item1`, `item2`, `item3`, `item4`]);
        const itemQuantities = useFormReturn.getValues([`qty1`, `qty2`, `qty3`, `qty4`]);
        itemNumbers.forEach((itemNum, index) => {
            if (itemNum !== ``) {
                itemToAddArray.push({
                    item: Item.uglyItem(itemNum),
                    list: `QuickAdd - Header`,
                    unitsOrdered: parseInt(itemQuantities[index]),
                });
            }
        });
        return itemToAddArray;
    };

    /**
     * Navigates to /quickadd with completed item numbers
     */
    const navQuickAddPage = () => {
        // Build url
        let url = `/quickadd`;
        const itemNumbers = useFormReturn.getValues([`item1`, `item2`, `item3`, `item4`]);
        const itemQuantities = useFormReturn.getValues([`qty1`, `qty2`, `qty3`, `qty4`]);
        itemNumbers.forEach((itemNum, index) => {
            if (itemNum !== ``) {
                url = UrlClass.addParam(url, `item${index + 1}`, itemNum);
                url = UrlClass.addParam(url, `itemQty${index + 1}`, itemQuantities[index]);
            }
        });

        // Navigate to page
        location.assign(url);
    };

    /**
     * Triggers error modal display and state reset
     * @param message
     */
    const showErrorModal = (message: string) => {
        closeMegaMenu();
        setEnableFormOperations(true);
        setErrorMessage(message);
    };

    /**
     * Submits the quickAddForm
     */
    const submitQuickAddForm = () => {
        // Clear existing messaging
        setItem1SuccessMessage(``);
        setItem2SuccessMessage(``);
        setItem3SuccessMessage(``);
        setItem4SuccessMessage(``);

        // Prevent double submission
        setEnableFormOperations(false);

        // Perform order add
        addToOrder(buildItemAddArray());
    };

    /**
     * Template
     */
    return (
        <>
            <div className="h4 mb-2">
                <FontAwesomeIcon icon={faBoltLightning} /> Quick-Add
            </div>
            <FormProvider {...useFormReturn}>
                <form
                    onSubmit={(event) => {
                        useFormReturn
                            .handleSubmit(submitQuickAddForm)(event)
                            .catch((handleSubmitErr) => {
                                showErrorModal(handleSubmitErr.message);
                            });
                    }}
                >
                    <div>
                        <div className="row mb-2">
                            <div className="col-7">
                                <Input
                                    aria-label="Header Quick Add Item Number 1"
                                    error={useFormReturn?.formState?.errors?.item1?.message as string}
                                    label="Item #"
                                    maxLength={15}
                                    name="item1"
                                    success={item1SuccessMessage}
                                    {...useFormReturn.register(`item1`)}
                                />
                            </div>
                            <div className="col-4">
                                <Input
                                    aria-label="Header Quick Add Quantity 1"
                                    defaultValue="1"
                                    error={useFormReturn?.formState?.errors?.qty1?.message as string}
                                    inputMode="numeric"
                                    label="QTY"
                                    maxLength={3}
                                    name="qty1"
                                    {...useFormReturn.register(`qty1`)}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-7">
                                <Input
                                    aria-label="Header Quick Add Item Number 2"
                                    error={useFormReturn?.formState?.errors?.item2?.message as string}
                                    label="Item #"
                                    maxLength={15}
                                    name="item2"
                                    success={item2SuccessMessage}
                                    {...useFormReturn.register(`item2`)}
                                />
                            </div>
                            <div className="col-4">
                                <Input
                                    aria-label="Header Quick Add Quantity 2"
                                    defaultValue="1"
                                    error={useFormReturn?.formState?.errors?.qty2?.message as string}
                                    inputMode="numeric"
                                    label="QTY"
                                    maxLength={3}
                                    name="qty2"
                                    {...useFormReturn.register(`qty2`)}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-7">
                                <Input
                                    aria-label="Header Quick Add Item Number 3"
                                    error={useFormReturn?.formState?.errors?.item3?.message as string}
                                    label="Item #"
                                    maxLength={15}
                                    name="item3"
                                    success={item3SuccessMessage}
                                    {...useFormReturn.register(`item3`)}
                                />
                            </div>
                            <div className="col-4">
                                <Input
                                    aria-label="Header Quick Add Quantity 3"
                                    defaultValue="1"
                                    error={useFormReturn?.formState?.errors?.qty3?.message as string}
                                    inputMode="numeric"
                                    label="QTY"
                                    maxLength={3}
                                    name="qty3"
                                    {...useFormReturn.register(`qty3`)}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-7">
                                <Input
                                    aria-label="Header Quick Add Item Number 4"
                                    error={useFormReturn?.formState?.errors?.item4?.message as string}
                                    label="Item #"
                                    maxLength={15}
                                    name="item4"
                                    success={item4SuccessMessage}
                                    {...useFormReturn.register(`item4`)}
                                />
                            </div>
                            <div className="col-4">
                                <Input
                                    aria-label="Header Quick Add Quantity 4"
                                    defaultValue="1"
                                    error={useFormReturn?.formState?.errors?.qty4?.message as string}
                                    inputMode="numeric"
                                    label="QTY"
                                    maxLength={3}
                                    name="qty4"
                                    {...useFormReturn.register(`qty4`)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-11 mb-1">
                            <Button
                                className="btn-block"
                                data-e2e="quickAddToOrderButton"
                                disabled={!useFormReturn.formState.isValid || !enableFormOperations || !useFormReturn.formState.isDirty}
                                type="submit"
                                variant="red"
                                size="lg"
                            >
                                Add to Order
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-11">
                            <LinkButton
                                disabled={!useFormReturn.formState.isValid || !enableFormOperations || !useFormReturn.formState.isDirty}
                                e2e="quickAddToLinkButton"
                                onClick={addItemsToList}
                                text="Add To List"
                            />
                        </div>
                    </div>

                    {user.isLoggedIn() && (
                        <LinkButton
                            e2e="ShowMoreQuickAddFields"
                            onClick={navQuickAddPage}
                            text="Show more Quick-Add fields"
                        />
                    )}
                </form>
            </FormProvider>
        </>
    );
};
