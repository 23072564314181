import * as React from 'react';
import {useState} from 'react';

import {AddToListModal} from './add-to-list-modal/AddToListModal';
import {CreateNewListModal} from './CreateNewListModal';
import {ListPickerRes} from './list.class';
import {UserList} from './lists/lists.class';

interface PickListWorkflow {
    getListId: (listPickerRes: ListPickerRes) => void;
    itemDesc: string;
    itemImage: string;
    onClose: () => void;
    show: boolean;
    userLists: UserList[];
}

export const PickListWorkflow = ({getListId, itemDesc, itemImage, onClose, show, userLists}: PickListWorkflow) => {
    const [showAddToListModal, setShowAddToListModal] = useState(show);
    const [showCreateNewListModal, setShowCreateNewListModal] = useState(false);

    /**
     * Template
     */
    return (
        <>
            <AddToListModal
                getListId={getListId}
                itemDesc={itemDesc}
                itemImage={itemImage}
                onClose={onClose}
                showCreateNewListModal={() => {
                    setShowAddToListModal(false);
                    setShowCreateNewListModal(true);
                }}
                show={showAddToListModal}
                userLists={userLists}
            />
            <CreateNewListModal
                afterCreateNewListRes={(listId, newListName) => getListId({listId, listName: newListName})}
                listOperation={{listAction: `add`, listId: `newList`, listName: ``}}
                onClose={onClose}
                show={showCreateNewListModal}
            />
        </>
    );
};
