import {AskToSaveCardModal, AskToSaveRes} from '../../shared/credit-cards/AskToSaveCardModal/AskToSaveCardModal';
import {CardDetail, CCList, UpdateCreditCardDetail} from '../../shared/credit-cards/credit-card.class';
import {ErrorModal} from '../../shared/error-handling/ErrorModal';
import {FetchService} from '../fetch/fetch.service';
import {ImpError} from '../imp-error/imp-error.class';
import {ProgressOverlay} from '../components/ProgressOverlay';
import {ReactClientService} from '../react/react-client.service';
import {UsersService} from '../users/users.service';
import {UserStateService} from '../users/user-state.service';

export class CreditCardsService {
    private readonly _reactClientService: ReactClientService;

    constructor(
        private _fetchService: FetchService,
        private _usersService: UsersService,
        private _userStateService: UserStateService,
    ) {
        this._reactClientService = new ReactClientService();
    }

    /**
     * Adds credit card for payment
     * @param componentName - Component performing the addCreditCard
     * @param saveCard - Save card on the account
     * @param defaultCard
     */
    public addCreditCard(componentName: string, saveCard?: boolean, defaultCard?: boolean) {
        if (this._usersService.getCurrentUser().caBillTo) {
            const [root] = this._reactClientService.appendComponent(
                ErrorModal,
                {errorMsg: `at this point we can not accept Canadian credit cards.`, onClose: () => root.unmount(), show: true},
                $(`#reactModalElement`)[0],
            );
            return;
        }
        if (typeof saveCard !== `boolean`) {
            // Determine if the user wishes to save the card
            this._askToSave()
                .then((askToSaveRes) => {
                    if (askToSaveRes) {
                        this._addCreditCardWorkflow(componentName, askToSaveRes.saveCard, askToSaveRes.setDefault);
                    }
                })
                .catch(() => {
                    this._showError();
                });
        } else {
            this._addCreditCardWorkflow(componentName, saveCard, defaultCard);
        }
    }

    /**
     * Get CardDetail for a specific cardID
     * @param cardID
     */
    public getCreditCard(cardID: string) {
        return this._fetchService.get<CardDetail>(`/api/credit-cards/getCreditCard/${cardID}`);
    }

    /**
     * Get a list of credit cards for an account
     */
    public getAllCards() {
        return this._fetchService.get<CCList>(`/api/credit-cards/getAllCards`);
    }

    /**
     * TBD
     * @param cardID
     */
    public removeCreditCard(cardID: string) {
        return this._fetchService.delete<void>(`/api/credit-cards/removeCreditCard/${cardID}`);
    }

    /**
     * Update card details for a given cardID
     * @param cardID
     * @param updateCreditCardDetail
     */
    public updateCreditCard(cardID: string, updateCreditCardDetail: UpdateCreditCardDetail) {
        return this._fetchService.post<CCList>(`/api/credit-cards/updateCreditCard/${cardID}`, updateCreditCardDetail);
    }

    /**
     * Adds credit card for payment
     * @param componentName - Component performing the addCreditCard
     * @param saveCard - Save card on the account
     * @param defaultCard - Set card as default on the account
     * @private
     */
    private _addCreditCardWorkflow(componentName: string, saveCard: boolean, defaultCard: boolean) {
        // Record addCreditCard action
        this._userStateService.recordAddCreditCardAction(componentName, saveCard, defaultCard);

        // Get FreedomPay URL
        this._getUrl(saveCard, defaultCard)
            .then((getUrlRes) => {
                this._userStateService.clearPendingAction();
                location.assign(getUrlRes);
            })
            .catch((getUrlErr: ImpError) => {
                if (getUrlErr.status === 429) {
                    window.location.assign('/login?wasLockout=true');
                } else if (getUrlErr.status === 403) {
                    this._usersService.showLoginPopUp(null, null, true);
                } else {
                    this._showError();
                }
            });
    }

    /**
     * Asks user if they wish to saveCard
     */
    private _askToSave(): Promise<AskToSaveRes> {
        return new Promise((resolve) => {
            const [root] = this._reactClientService.appendComponent(
                AskToSaveCardModal,
                {
                    isPunchout: this._usersService.getCurrentUser().isPunchout,
                    onClose: () => root.unmount(),
                    onContinue: (askToSaveRes) => {
                        resolve(askToSaveRes);
                    },
                    show: true,
                },
                $(`#reactModalElement`)[0],
            );
        });
    }

    /**
     * Gets URL from FreedomPay for adding a new credit card
     * @param saveCard - Save card on the account
     * @param defaultCard - Save as default card
     * @returns - Freedom pay URL to add a CC
     */
    private _getUrl(saveCard: boolean, defaultCard: boolean): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this._fetchService
                .get<any>(`/api/FreedomPaySoapCreates/getURL?saveCard=${saveCard}&defaultCard=${defaultCard}`)
                .then((getUrlRes) => {
                    resolve(getUrlRes.CreateVerificationTransactionResult.CheckoutUrl);
                })
                .catch((getUrlErr: ImpError) => {
                    reject(getUrlErr);
                });
        });
    }

    /**
     * Shows error to user
     * @private
     */
    private _showError() {
        ProgressOverlay.deactivate();
        const [root] = this._reactClientService.appendComponent(
            ErrorModal,
            {errorMsg: `There was a problem adding your credit card`, onClose: () => root.unmount(), show: true},
            $(`#reactModalElement`)[0],
        );
    }
}
