import {Observable, Observer} from 'rxjs';

import {FetchService} from '../fetch/fetch.service';
import {gaLegacyCustomEvent} from '../ga/ga-legacy.functions';
import {ImpError} from '../imp-error/imp-error.class';
import {OrdersService} from '../orders/orders.service';
import {OrdersWorkflow} from '../orders/orders.workflow';
import {ProgressOverlay} from '../components/ProgressOverlay';
import {Promo, PromoCodeAction, UpdatePromoCodeRes} from '../../shared/promos/promo.class';
import {PromoItemPickerModal} from '../../shared/promos/PromoItemPickerModal';
import {pushGaEvent} from '../ga/ga.functions';
import {ReactClientService} from '../react/react-client.service';
import {UsersService} from '../users/users.service';
import {User} from '../../shared/users/user.class';

export class PromosService {
    private readonly _currentUser: User = null;
    private readonly _reactClientService: ReactClientService;

    constructor(
        private _fetchService: FetchService,
        private _ordersService: OrdersService,
        private _ordersWorkflow: OrdersWorkflow,
        private _usersService: UsersService,
    ) {
        this._currentUser = this._usersService.getCurrentUser();
        this._reactClientService = new ReactClientService();
    }

    /**
     * Performs action with provided promo code on order
     * @param pcode - Promo code to perform action on
     * @param componentName - Component performing the applyPromoCode
     * @param selectedPromoEmail - Email to send promo redemption
     * @param selectedPromoItem - Selected promo item
     * @param selectedPromoTier - Selected promo tier
     */
    public applyPromoCode(
        pcode: string,
        componentName: string,
        selectedPromoEmail?: string,
        selectedPromoItem?: string,
        selectedPromoTier?: string,
    ): Observable<UpdatePromoCodeRes> {
        return new Observable((observer: Observer<UpdatePromoCodeRes>) => {
            ProgressOverlay.activate();

            // Get orderNumber to apply promo to
            this._ordersWorkflow.createOrPickOrder(componentName).subscribe({
                next: (createOrPickOrderRes) => {
                    // User cancelled transaction
                    if (!createOrPickOrderRes) {
                        ProgressOverlay.deactivate();
                        observer.next(null);
                        observer.complete();
                        return;
                    }

                    // Load order if not currentOrder
                    if (this._ordersService.currentOrderNumber !== createOrPickOrderRes) {
                        this._ordersService
                            .loadOrder(createOrPickOrderRes)
                            .then(() => {
                                this._applyPromoCode(
                                    pcode,
                                    componentName,
                                    createOrPickOrderRes,
                                    observer,
                                    selectedPromoEmail,
                                    selectedPromoItem,
                                    selectedPromoTier,
                                );
                            })
                            .catch((loadOrderErr: ImpError) => {
                                ProgressOverlay.deactivate();
                                observer.error(loadOrderErr);
                                observer.complete();
                            });
                    } else {
                        this._applyPromoCode(
                            pcode,
                            componentName,
                            createOrPickOrderRes,
                            observer,
                            selectedPromoEmail,
                            selectedPromoItem,
                            selectedPromoTier,
                        );
                    }
                },
                error: (createOrPickOrderErr: ImpError) => {
                    ProgressOverlay.deactivate();
                    observer.error(createOrPickOrderErr);
                    observer.complete();
                },
            });
        });
    }

    /**
     * Adds or removes provided promo code to order
     * @param orderNbr - Order number associated with promo code
     * @param pcode - Promo code to apply
     * @param action - 'add', 'remove' or 'verify'
     * @param componentName - Component performing the updatePromoCode
     * @param selectedPromoEmail - Email to send promo redemption
     * @param selectedPromoItem - Selected promo item
     * @param selectedPromoTier - Selected promo tier
     */
    public updatePromoCode(
        orderNbr: string,
        pcode: string,
        action: PromoCodeAction,
        componentName: string,
        selectedPromoEmail?: string,
        selectedPromoItem?: string,
        selectedPromoTier?: string,
    ) {
        return new Promise<UpdatePromoCodeRes>((resolve, reject) => {
            this._fetchService
                .post<UpdatePromoCodeRes>(`/api/promos/updatePromoCode/${orderNbr}/${pcode}/${action}`, {
                    selectedPromoEmail,
                    selectedPromoItem,
                    selectedPromoTier,
                })
                .then((updatePromoCodeRes) => {
                    // Record removals in GA
                    if (action === `remove`) {
                        gaLegacyCustomEvent({
                            eventAction: `${componentName}` as any,
                            eventCategory: `Apply Coupon`,
                            eventLabel: `Code Removed | ${pcode}`,
                        });
                    }

                    // Refresh currentOrder
                    this._ordersService
                        .loadOrder(orderNbr)
                        .then(() => {
                            resolve(updatePromoCodeRes);
                        })
                        .catch((loadOrderErr: ImpError) => {
                            reject(loadOrderErr);
                        });
                })
                .catch((updatePromoCodeErr: ImpError) => {
                    gaLegacyCustomEvent({
                        eventAction: `${componentName}` as any,
                        eventCategory: `Apply Coupon`,
                        eventLabel: `Error: ${updatePromoCodeErr.message} | ${pcode}`,
                    });
                    reject(updatePromoCodeErr);
                });
        });
    }

    /**
     * Adds promo code to provided order
     * @param pcode - Promo code to add
     * @param componentName - Component performing the applyPromoCode
     * @param orderNbr - Order number associated with promo code
     * @param observer - Observer to return results
     * @param selectedPromoEmail - Email to send promo redemption
     * @param selectedPromoItem - Selected promo item
     * @param selectedPromoTier - Selected promo tier
     * @private
     */
    private _applyPromoCode(
        pcode: string,
        componentName: string,
        orderNbr: string,
        observer: Observer<UpdatePromoCodeRes>,
        selectedPromoEmail: string,
        selectedPromoItem: string,
        selectedPromoTier: string,
    ) {
        this.updatePromoCode(orderNbr, pcode, `add`, componentName, selectedPromoEmail, selectedPromoItem, selectedPromoTier)
            .then((updatePromoCodeRes) => {
                ProgressOverlay.deactivate();

                // Show PromoItemPickerComponent for multi-tier campaigns
                const promoTierOptions = Promo.getPromoTierOptions(updatePromoCodeRes);
                if (promoTierOptions && !selectedPromoTier) {
                    const [root] = this._reactClientService.appendComponent(
                        PromoItemPickerModal,
                        {
                            applyPromoObserver: observer,
                            componentName,
                            onHide: (): void => {
                                root.unmount();
                            },
                            pcode,
                            promoTierOptions,
                            show: true,
                            user: this._currentUser,
                        },
                        document.querySelector(`[data-component='${componentName}']`),
                    );
                } else {
                    // Record analytics on successful promo selection
                    gaLegacyCustomEvent({eventAction: `${componentName}` as any, eventCategory: `Apply Coupon`, eventLabel: `Success`});
                    pushGaEvent(`ga4_apply_promo`, {coupon: pcode});

                    // Return response
                    observer.next(updatePromoCodeRes);
                    observer.complete();
                }
            })
            .catch((updatePromoCodeErr: ImpError) => {
                ProgressOverlay.deactivate();
                observer.error(updatePromoCodeErr);
                observer.complete();
            });
    }
}
