import {ItemToAdd, MultipleItemAddTypes} from '../../shared/order-items/order-items.class';
import {LocalStorageService} from '../local-storage/local-storage.service';
import {UpdateItemsOnListActionTypes, UpdateListItem} from '../../shared/lists/list.class';

export const PENDING_ACTION_LIFE_MINS = 5;

type GenericPendingActionType = `createOrder` | `logon` | `showScanner`;
type PendingActionType = `addCreditCard` | `addToList` | `addToOrder` | `enrollAutoReorder` | GenericPendingActionType;

interface PendingAction {
    addCreditCardSaveCard?: boolean;
    addCreditCardSaveDefaultCard?: boolean;
    addToListAction?: UpdateItemsOnListActionTypes;
    addToListItemDesc?: string;
    addToListItemImage?: string;
    addToListItems?: UpdateListItem[];
    addToOrderErroredItems?: ItemToAdd[];
    addToOrderItems?: ItemToAdd[];
    addToOrderMethod?: MultipleItemAddTypes;
    componentName: string;
    enrollAutoReorderItem?: ItemToAdd;
    type: PendingActionType;
}

export class UserStateService {
    public createNewOrderSelected = false;
    private _pendingAction: PendingAction;

    constructor(private _localStorageService: LocalStorageService) {
        this._onInit();
    }

    private _onInit() {
        // Reconstitute service state
        this._buildServiceStateFromLocalStorage();
    }

    /**
     * Returns the pending addCreditCardSaveCard
     */
    public get addCreditCardSaveCard(): boolean {
        return this._pendingAction.addCreditCardSaveCard;
    }

    /**
     * Returns the pending addCreditCardSaveDefaultCard
     */
    public get addCreditCardSaveDefaultCard(): boolean {
        return this._pendingAction.addCreditCardSaveDefaultCard;
    }

    /**
     * Returns the pending addToListItems
     */
    public get addToListAction(): UpdateItemsOnListActionTypes {
        return this._pendingAction.addToListAction || `add`;
    }

    /**
     * Returns the pending addToListItems
     */
    public get addToListItems(): UpdateListItem[] {
        return this._pendingAction.addToListItems || [];
    }

    /**
     * Returns the pending addToListItems
     */
    public get addToListItemDesc(): string {
        return this._pendingAction.addToListItemDesc || ``;
    }

    /**
     * Returns the pending addToListItems
     */
    public get addToListItemImage(): string {
        return this._pendingAction.addToListItemImage || ``;
    }

    /**
     * Returns the pending addToOrderErroredItems
     */
    public get addToOrderErroredItems(): ItemToAdd[] {
        return this._pendingAction?.addToOrderErroredItems || [];
    }

    /**
     * Returns the pending addToOrderItems
     */
    public get addToOrderItems(): ItemToAdd[] {
        return this._hasPendingItemsToAdd() ? this._pendingAction.addToOrderItems : [];
    }

    /**
     * Returns the pending addToOrderIncrement
     */
    public get addToOrderMethod(): MultipleItemAddTypes {
        return this._pendingAction.addToOrderMethod;
    }

    /**
     * Clears any pending action
     */
    public clearPendingAction() {
        // Clear class props
        this._pendingAction = null;
        this.createNewOrderSelected = false;

        // Clear localstorage
        this._localStorageService.removeItem(`createNewOrder`);
        this._localStorageService.removeItem(`pendingAction`);
    }

    /**
     * Returns componentName with pending action
     */
    public get componentName(): string {
        return this._pendingAction.componentName;
    }

    /**
     * Returns the pending enrollAutoReorderItem
     */
    public get enrollAutoReorderItem(): ItemToAdd {
        return this._pendingAction.enrollAutoReorderItem || null;
    }

    /**
     * Returns true if any pending workflow actions exist
     */
    public hasPendingActions(): boolean {
        return !!this._pendingAction;
    }

    /**
     * Returns true if the component has a pending addToList
     * @param componentName - Component to check
     */
    public hasPendingAddCreditCard(componentName: string): boolean {
        return !!(
            this._pendingAction &&
            this._pendingAction.componentName === componentName &&
            this._pendingAction.type === `addCreditCard`
        );
    }

    /**
     * Returns true if the component has a pending addToList
     * @param componentName - Component to check
     */
    public hasPendingAddToList(componentName: string): boolean {
        return !!(this._pendingAction && this._pendingAction.componentName === componentName && this._pendingAction.type === `addToList`);
    }

    /**
     * Returns true if the component has a pending addToOrder
     * @param componentName - Component to check
     */
    public hasPendingAddToOrder(componentName: string): boolean {
        return !!(this._hasPendingItemsToAdd() && this._pendingAction.componentName === componentName);
    }

    /**
     *
     * @param componentName - Component to check
     * @param genericAction - Generic action to check
     */
    public hasPendingAction(componentName: string, genericAction: GenericPendingActionType) {
        return !!(this._pendingAction && this._pendingAction.componentName === componentName && this._pendingAction.type === genericAction);
    }

    /**
     * Returns true if the component has a pending createOrder
     * @param componentName
     */
    public hasPendingEnrollAutoReorder(componentName: string): boolean {
        return !!(
            this._pendingAction &&
            this._pendingAction.componentName === componentName &&
            this._pendingAction.type === `enrollAutoReorder`
        );
    }

    /**
     * Records a pending addCreditCard
     * @param componentName - Component performing addToList
     * @param saveCard - Save card on the account
     * @param defaultCard - Set card as default on the account
     */
    public recordAddCreditCardAction(componentName: string, saveCard: boolean, defaultCard: boolean) {
        this._pendingAction = {
            addCreditCardSaveCard: saveCard,
            addCreditCardSaveDefaultCard: defaultCard,
            componentName,
            type: `addCreditCard`,
        };
        this._savePendingActionStorage();
    }

    /**
     * Records a pending addToList
     * @param componentName - Component performing addToList
     * @param addToListItems - Array of items to add
     * @param itemDesc
     * @param imagePath
     */
    public recordAddToListAction(componentName: string, addToListItems: UpdateListItem[], itemDesc?: string, imagePath?: string) {
        this._pendingAction = {
            componentName,
            addToListItems,
            type: `addToList`,
        };
        if (itemDesc) {
            this._pendingAction.addToListItemDesc = itemDesc;
        }
        if (imagePath) {
            this._pendingAction.addToListItemImage = imagePath;
        }
        this._savePendingActionStorage();
    }

    /**
     * Records a pending addToOrder
     * @param componentName - Component performing the addToOrder
     * @param itemsToAdd - Array of items to add
     * @param method - Increment (update) or replace (add) existing order lines
     */
    public recordAddToOrderAction(componentName: string, itemsToAdd: ItemToAdd[], method: MultipleItemAddTypes) {
        // Separate erroredItems
        const addToOrderItems = [];
        const addToOrderErroredItems = [];
        for (const itemToAdd of itemsToAdd) {
            if (itemToAdd.error) {
                addToOrderErroredItems.push(itemToAdd);
            } else {
                addToOrderItems.push(itemToAdd);
            }
        }

        // Record pendingAction
        this._pendingAction = {
            addToOrderErroredItems,
            addToOrderItems,
            addToOrderMethod: method,
            componentName,
            type: `addToOrder`,
        };
        this._savePendingActionStorage();
    }

    /**
     * Records that the user has selected createNewOrder
     */
    public recordCreateNewOrderSelected() {
        this.createNewOrderSelected = true;
        this._localStorageService.setItem(`createNewOrder`, `true`, PENDING_ACTION_LIFE_MINS);
    }

    /**
     * TBD
     * @param componentName
     * @param itemToAdd
     */
    public recordEnrollAutoReorderAction(componentName: string, itemToAdd: ItemToAdd) {
        this._pendingAction = {
            componentName,
            enrollAutoReorderItem: itemToAdd,
            type: `enrollAutoReorder`,
        };
        this._savePendingActionStorage();
    }

    /**
     * Records a generic pendingAction the user is trying to perform
     * @param componentName - Component performing the pendingAction
     * @param pendingAction - The pendingAction being performed
     */
    public recordPendingAction(componentName: string, pendingAction: GenericPendingActionType) {
        this._pendingAction = {
            componentName,
            type: pendingAction,
        };
        this._savePendingActionStorage();
    }

    /**
     * Reconstructs service state from localstorage
     * @private
     */
    private _buildServiceStateFromLocalStorage() {
        // Rebuild pendingAction
        try {
            const pendingActionStorage = JSON.parse(this._localStorageService.getItem(`pendingAction`));
            if (pendingActionStorage) {
                this._pendingAction = pendingActionStorage;
            }
        } catch {
            // Error silently
        }

        // Rebuild _createNewOrderSelected
        const createNewOrderStorage = this._localStorageService.getItem(`createNewOrder`);
        if (createNewOrderStorage === `true`) {
            this.createNewOrderSelected = true;
        }
    }

    /**
     * Determines if the pendingAction contains addToOrderItems
     * @private
     */
    private _hasPendingItemsToAdd(): boolean {
        return !!(
            this._pendingAction &&
            this._pendingAction.type === `addToOrder` &&
            this._pendingAction.addToOrderItems &&
            this._pendingAction.addToOrderItems.length
        );
    }

    /**
     * Writes pendingAction to disk
     * @private
     */
    private _savePendingActionStorage() {
        this._localStorageService.setItem(`pendingAction`, JSON.stringify(this._pendingAction), PENDING_ACTION_LIFE_MINS);
    }
}
